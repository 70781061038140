/* ProductCategory View, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="productcategory-view" class="entity-view">
      <v-card-title>View Product Category</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>

        <Can I="update" on="Reference">
        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'productcategory-edit', params: { id: entity.Id } }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <span>Edit</span>
        </v-btn>
        </Can>
        </v-col>
        <v-col class="text-right">
        <Can I="create" on="Reference">
        <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'productcategory-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Product Category</span>
        </v-btn>
        </Can>
        <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'productcategory-list'}"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiViewList }}
          </v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-simple-table>
        <thead>
          <tr>
            <th colspan="2">Details</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Name</td>
        <td>{{ entity.Name }}</td>
      </tr>

        </tbody>
      </v-simple-table>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import { Can } from '@casl/vue'

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiClose
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  components: {
    Can
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.ProductCategory);
    const loading = ref(false);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchProductCategory', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'productcategory-list'});
        })
    }

    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);



    return {
      loading,
      entity,
      Display,

      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

